const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ['/terms'],
    exact: true,
    component: "Terms",
  },
  {
    path: ['/privacy_policy'],
    exact: true,
    component: "PrivacyPolicy",
  },
  {
    path: ['/refund_policy'],
    exact: true,
    component: "Refund",
  },
  {
    path: ['/delete_account'],
    exact: true,
    component: "DeleteAccount",
  },
];

export default routes;
